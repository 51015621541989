@import "./custom.scss";

.results {
    width: 100%;
    height: min-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
    align-content: center;
    flex-wrap: nowrap;
    padding-bottom: 64px;

    .info {
        box-sizing: border-box;
        width: 100%;
        height: min-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 16px 64px 16px 64px;
        background-color: var(--Secondary, #313f4d);
        overflow: hidden;
        flex: 1 0 0px;
        position: relative;
        align-content: center;
        flex-wrap: nowrap;
        gap: 8px;

        .tone {
            gap: 30px!important;
        }
        .article,
        .tone {
            height: min-content;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            overflow: hidden;
            flex: 1 0 0px;
            position: relative;
            align-content: center;
            flex-wrap: wrap;
            gap: 8px;

            .title-info {
                flex-shrink: 0;
                width: auto;
                height: auto;
                white-space: pre;
                min-width: 80px;
                position: relative;
                color: var(--White, #e8ecf1);
                line-height: 1.2;
                font-weight: 600;
            }

            .url,
            .tone-info {
                flex-shrink: 0;
                width: auto;
                height: auto;
                white-space: pre;
                position: relative;
                font-weight: 500;
                font-style: normal;
                font-family: "Inter-Medium", "Inter", sans-serif;
                color: var(--White, #e8ecf1);
                font-size: 16px;
                letter-spacing: 0px;
                line-height: 1.4;
            }
        }
    }

    .content {
        box-sizing: border-box;
        height: min-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 64px 0px 48px 0px;
        max-width: 900px;
        flex: 0.8 0 0px;
        align-content: flex-start;
        flex-wrap: nowrap;
        gap: 48px;

        .title-content {
            height: auto;
            white-space: pre-wrap;
            word-wrap: break-word;
            word-break: break-word;
            flex: 1 0 0px;
            position: relative;
            line-height: 1.2;
        }

        .video-results {
            width:100%;
            height: min-content;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex: 1 0 0px;
            align-content: center;
            flex-wrap: wrap;
        }
    }

    .variation {
        height: 61px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        flex: 1 0 0px;
        position: relative;
        align-content: center;
        flex-wrap: nowrap;
        gap: 10px;

        .variation-btn {
            box-sizing: border-box;
            flex-shrink: 0;
            width: min-content;
            height: min-content;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 16px 24px 16px 24px;
            background-color: var(--Secondary, #313f4d);
            overflow: hidden;
            position: relative;
            align-content: center;
            flex-wrap: nowrap;
            gap: 16px;
            border-radius: 8px;
            cursor: pointer;

            span {
                flex-shrink: 0;
                width: auto;
                height: auto;
                white-space: pre;
                position: relative;
                font-weight: 600;
                font-style: normal;
                font-family: "Inter-SemiBold", "Inter", sans-serif;
                color: #e8ecf1;
                font-size: 18px;
                letter-spacing: 0px;
                line-height: 1.2;
            }
        }
    }
}