@import "./custom.scss";

.mini-card {
  width: 79px;
  height: 140px;
  display: block;
  overflow: hidden;
  aspect-ratio: 0.5625 / 1;
  border-radius: 8px;
  position: relative;

  img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .delete {
    width: 17px;
    height: 17px;
    display: block;
    background-color: #cc0011;
    overflow: visible;
    position: absolute;
    border-radius: 70px;
  }
}

.mini-card2 {
  width: 79px;
  height: 140px;
  display: block;
  overflow: visible;
  aspect-ratio: 0.5625 / 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  border-radius: 8px;
  &.show {
    .round {
      background-color: violet;
    }
  }
  .round {
    width: 17px;
    height: 17px;
    display: block;
    background-color: #cc0011;
    overflow: visible;
    position: absolute;
    border-radius: 70px;
    right: 0;
    top: -8px;
    right: -8px;
    cursor: default;

    .delete {
      position: absolute;
      top: 2px;
      left: 2px;
    }
  }
}

.add-card {
  flex-shrink: 0;
  width: auto;
  height: auto;
  white-space: pre;
  position: relative;
  font-weight: 600;
  font-style: normal;
  font-family: "Inter-SemiBold", "Inter", Verdana, sans-serif;
  color: $third;
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 1.2;
  cursor: pointer;
}
