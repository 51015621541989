@import "./custom.scss";

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .login-box{
    min-width: 400px;
    background-color: $secondary;
    border-radius: 32px;
    padding: 64px 64px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    .inputs{
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .submit{
      padding: 16px 24px;
      background-color: $primary;
      border: none;
      color: $white;
      font-size: 24px;
      font-weight: 600;
      border-radius: 4px;
    }
    input {
      background-color: $third;
      border: 4px solid $white;
      height: min-content;
      padding: 16px 32px;
      color: $white;
      font-weight: 800;
      font-size: 0.8em;
      max-width: 800px;
      border-radius: 4px;
      border: none;

      &.error
      {
        animation: shake 0.2s ease-in-out 0s 2;
        box-shadow: 0 0 0.5em white;
        text-align: left;
      }
    }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $white;
      opacity: 0.5; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $white;
      opacity: 0.5;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: $white;
      opacity: 0.5;
    }

    .error{
      // height: 24px;
      font-weight: 700;
      color: #eb3700;
      background-color: inherit;
      text-align: left;
      font-size: 0.8em;
      line-height: 1.1em;
      padding: 0;
    }
  }
    
}