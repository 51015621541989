@import "./custom.scss";

.inner {
  box-sizing: border-box;
  width: 100%;
  height: min-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 64px 56px 64px;
  background-color: $secondary;
  overflow: auto;
  align-content: center;
  flex-wrap: nowrap;
  gap: 24px;
  position: absolute;

  .menu {
    flex-shrink: 0;
    height: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
    flex: 1 0 0px;
    position: relative;

    align-content: flex-end;
    flex-wrap: nowrap;
    gap: 32px;

    .item {
      box-sizing: border-box;
      flex-shrink: 0;
      width: min-content;
      height: 32px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: relative;

      align-content: center;
      flex-wrap: nowrap;
      gap: 10px;

      border-style: solid;
      border-top-width: 0px;
      border-bottom-width: 2px;
      border-left-width: 0px;
      border-right-width: 0px;
      opacity: 0.5;
      border-color: $white;

      &.active {
        border-color: $white;
        opacity: 1;
      }

      span {
        flex-shrink: 0;
        width: auto;
        height: auto;
        white-space: pre;
        position: relative;
        font-weight: 600;
        font-style: normal;
        font-family: "Inter-SemiBold", "Inter", Verdana, sans-serif;
        color: $white;
        font-size: 18px;
        letter-spacing: 0px;
        line-height: 1.4;
      }
    }
  }

  .container-list {
    width: 100%;
    flex-shrink: 0;
    height: min-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    flex: 1 0 0px;
    position: relative;
    align-content: flex-start;
    flex-wrap: nowrap;
    gap: 24px;

    .one-list {
      flex-shrink: 0;
      height: min-content;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      overflow: hidden;
      flex: 1 0 0px;
      position: relative;
      align-content: flex-start;
      flex-wrap: nowrap;
      gap: 8px;
    }
  }
}

.inner-all {
  box-sizing: border-box;
  width: 100%;
  //height: 100%;
  display: block;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 64px 0px 64px;
  background-color: $secondary;
  overflow: auto;
  align-content: center;
  flex-wrap: nowrap;
  gap: 24px;
  position: absolute;

  .see-all-block {
    //width: 100%;
    //flex: 1 0 0px;
    box-sizing: border-box;
    //height: min-content;
    display: block;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    //padding: 16px 64px 56px 64px;
    background-color: $secondary;
    overflow: auto;
    align-content: center;
    flex-wrap: nowrap;
    gap: 24px;
    //position: absolute;

    .inner-see-all {
      width: 100%;
      flex-shrink: 0;
      height: min-content;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      overflow: hidden;
      //flex: 1 0 0px;
      position: relative;
      align-content: flex-start;
      flex-wrap: nowrap;
      gap: 24px;

      .container-all {
        width: 100%;
        flex-shrink: 0;
        height: min-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        //flex: 1 0 0px;
        position: relative;
        align-content: center;
        flex-wrap: nowrap;
        gap: 8px;

        .controls {
          width: 100%;
          flex-shrink: 0;
          height: min-content;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          overflow: visible;
          flex: 1 0 0px;
          position: relative;
          align-content: center;
          flex-wrap: nowrap;
          gap: 10px;
          cursor: pointer;

          span {
            flex-shrink: 0;
            width: auto;
            height: auto;
            white-space: pre;
            position: relative;
            font-weight: 500;
            font-style: normal;
            font-family: "Inter-Medium", "Inter", Verdana, sans-serif;
            color: $white;
            font-size: 18px;
            letter-spacing: 0px;
            line-height: 1.2;
          }
        }

        .upload-files {
          box-sizing: border-box;
          flex-shrink: 0;
          width: min-content;
          height: min-content;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px 8px 8px 8px;
          background-color: $third;
          overflow: hidden;
          position: relative;
          align-content: center;
          flex-wrap: nowrap;
          gap: 8px;
          border-radius: 8px;

          span {
            flex-shrink: 0;
            width: auto;
            height: auto;
            white-space: pre;
            position: relative;
            font-weight: 600;
            font-style: normal;
            font-family: "Inter-SemiBold", "Inter", Verdana, sans-serif;
            color: $white;
            font-size: 14px;
            letter-spacing: 0px;
            line-height: 1.2;
          }
        }

        .tab {
          flex-shrink: 0;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          flex: 1 0 0px;
          position: relative;
          align-content: center;
          flex-wrap: nowrap;
          gap: 10px;

          .nav-tabs {
            border: 0;
            justify-content: center !important;

            .nav-item {
              flex: inherit !important;
              margin-right: 30px;

              .nav-link {
                flex-shrink: 0;
                width: auto;
                height: auto;
                white-space: pre;
                min-width: 80px;
                position: relative;
                color: rgba(232, 236, 241, 0.5);
                line-height: 1.2;
                text-align: center;
              }

              .nav-item.show .nav-link,
              .nav-link.active {
                border: 0;
                border-bottom: 3px solid white;
                font-weight: bold;
                color: white;
                background-color: transparent;

              }

              .nav-link:focus,
              .nav-link:hover {
                border: 0;
                border-bottom: 3px solid white;
              }

              button {
                padding: 0 0 4px 0;
                font-family: 'Inter', Verdana;
                font-size: 16px;
                font-weight: 400;
              }
            }
          }

          .tab-content {
            height: calc(100vh - 420px);
            overflow: auto;
            width: 100%;

            .image-list-vertical {
              img {
                height: 120px;
                margin: 4px;
              }
            }

            .text-list-vertical {
              text-align: left;

              .item-text {
                width: max-content;
                display: inline-block;
                margin: 4px;
                vertical-align: middle;
              }
            }

            .music-list-vertical {
              text-align: center;

              .item-music {
                width: max-content;
                display: inline-block;
                margin: 4px;
                cursor: pointer;
                
                .music-top{
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  padding: 4px 8px;
                  align-items: center;
                  .music-title{
                    font-weight: 600;
                  }
                  .select{
                    font-size: 0.8em;
                  }
                }
              }
            }
          }

          .tab-test {
            height: 100px;
            background-color: red;
          }
        }
      }
    }
  }
}