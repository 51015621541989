@import "./custom.scss";

.working-slide {
  width: 274px; 
  height: 487px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 0px 0px 0px 0px;
  align-content: center;
  flex-wrap: nowrap;
  gap: 10px;
  aspect-ratio: 0.5625 / 1;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  border-radius: 0px 0px 0px 0px;

  .textbox {
    // box-sizing: border-box;
    // flex-shrink: 0;
    // width: min-content;
    /* 224px */
    // height: min-content;
    /* 80px */
    // display: flex;
    // flex-direction: row;
    // justify-content: center;
    // align-items: center;
    // padding: 4px 8px 4px 8px;
    // background-color: white;
    max-height: 30%;
    max-width: 224px;
    text-align: center;
    // overflow: hidden;
    // position: relative;
    // align-content: center;
    // flex-wrap: nowrap;
    // gap: 10px;
    // border-radius: 9px;

    span {
      // flex-shrink: 0;
      // height: auto;
      // white-space: pre-wrap;
      // word-wrap: break-word;
      // word-break: break-word;
      // position: relative;
      font-weight: 900;
      font-style: normal;
      font-family: "Montserrat", sans-serif;
      color: #000000;
      // letter-spacing: 0px;
      line-height: 1.5;
      // line-height: 1.35;
      // display: inline;
      box-decoration-break: clone;
      background: white;
      padding: 4px 8px;
      filter: url('#goo');
    }
  }
  .font-lg {
    font-size: 22px;
  }
  
  .font-md {
    font-size: 20px;
  }
  
  .font-sm {
    font-size: 14px;
  }
}
