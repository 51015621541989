@import "./custom.scss";

.validate-crop {
  box-sizing: border-box;
  width: min-content;
  height: min-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 8px 8px;
  background-color: $primary;
  overflow: hidden;
  position: relative;
  align-content: center;
  flex-wrap: nowrap;
  gap: 8px;
  border-radius: 8px;
  cursor: pointer;

  span {
      width: auto;
      height: auto;
      white-space: pre;
      position: relative;
      font-weight: 600;
      font-style: normal;
      font-family: "Inter-SemiBold", "Inter", sans-serif;
      color: $white;
      font-size: 14px;
      letter-spacing: 0px;
      line-height: 1.2;
    }
}

.side-left {
  flex-shrink: 0;
  flex: 1 0 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: $third;
  overflow: hidden;
  position: relative;
  align-content: center;
  flex-wrap: nowrap;

  &.cropbg {
      background-color: #000 !important;
    }
  .btn-success {
    color:#FFF;
    &:hover {
      color: #FFF;
    }
  }
  .toolbar {
    box-sizing: border-box;
    height: 49px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 0px 0px 16px;
    background-color: $white;
    overflow: hidden;
    align-content: center;
    flex-wrap: nowrap;
    gap: 16px;
    position: absolute;

    &.crop {
      background-color: #000;
      border-color: #000;
      border-style: solid;
      border-top-width: 0px;
      border-bottom-width: 0px;
      border-left-width: 0px;
      border-right-width: 2px;
    }

    .controls {
      box-sizing: border-box;
      flex-shrink: 0;
      width: min-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 16px 0px 0px;
      overflow: hidden;
      flex: 1 0 0px;
      position: relative;
      align-content: center;
      flex-wrap: nowrap;
      gap: 8px;

      &.end {
        justify-content: end;
      }

      .box {
        flex-shrink: 0;
        width: min-content;
        height: min-content;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        position: relative;
        align-content: center;
        flex-wrap: nowrap;
        gap: 10px;

        .icon-text-group {
          white-space: nowrap;
          z-index: 1;
          cursor: pointer;
          color: $secondary;

          &.disabled{
            opacity: 0.3;
            cursor:not-allowed;
          }
        }

        .icon-text-group:hover {
          background-color: white;
        }
      }
    }
  }
  .slide {
    box-sizing: border-box;
    flex-shrink: 0;
    //flex: 1 0 0px;
    margin-top: 49px;
    height: calc(100% - 49px);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 16px 0px 16px;
    overflow: hidden;
    position: relative;
    align-content: center;
    flex-wrap: nowrap;
    gap: 10px;
    border-radius: 0px 0px 0px 0px;
    .slide-open {
      flex-shrink: 0;
      flex: 1 0 0px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: relative;
      padding: 0px 0px 0px 0px;
      align-content: center;
      flex-wrap: nowrap;
      gap: 10px;
      border-radius: 0px 0px 0px 0px;
    }
    .arrowleft {
      flex-shrink: 0;
      width: 24px;
      height: 27px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: relative;
      padding: 0px 0px 0px 0px;
      align-content: center;
      flex-wrap: nowrap;
      gap: 10px;
      border-radius: 0px 0px 0px 0px;
    }
    .arrowright {
      flex-shrink: 0;
      width: 24px;
      height: 27px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: relative;
      padding: 0px 0px 0px 0px;
      align-content: center;
      flex-wrap: nowrap;
      gap: 10px;
      border-radius: 0px 0px 0px 0px;
    }
  }
}
