@import "./custom.scss";

html {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  flex-grow: 1;
  display: flex;
}

#root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.App{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.navbar > .container-fluid{
  padding: 0 64px;
}

/* Slides form */
.slide-container {
  margin: 2rem;
  background-color: aliceblue;
  padding: 1rem;
  border-radius: 25px;
}

.slide-title {
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  margin-bottom: 2rem;
}

.slide-content {
  margin-left: 2rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.slide-image {
  flex: 1;
}
.slide-texts {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
}

.slide-summary {
  width: 100%;
  height: 200px;
}

.compile-button {
  height: 50px;
  width: 200px;
  position: absolute;
}

.delete-slide {
  float: right;
  color: red;
  cursor: pointer;
}

.insert-slide-container {
  text-align: center;
}

.insert-slide-icon {
  color: green;
  font-weight: bold;
  font-size: 88px;
  cursor: pointer;
}

.slider-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.slider-group {
  display: flex;
  flex-direction: column;
}

/* Error */
.error {
  background-color: red;
  color: lightgrey;
  padding: 10px;
  font-weight: bold;
  text-align: center;
}

.slide-content .clear-btn {
  margin-left: 1rem;
}
