@import "./custom.scss";

.button-container{
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-top: 8px;
  
  .button{
    border-radius: 8px;
    display: flex;
    gap: 4px;
    background-color: $third;
    padding: 8px;
    font-weight: 600;
    align-items: center;
    cursor: pointer;

    .input{
      display: none;
    }
  }
}