@import "./custom.scss";

.toolbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  align-content: center;
  flex-wrap: nowrap;
  gap: 10px;

  .inner {
    box-sizing: border-box;
    flex: 1 0 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 24px 0px 8px;
    background-color: $background;
    overflow: hidden;
    position: relative;
    align-content: center;
    flex-wrap: nowrap;

    .back {
      box-sizing: border-box;
      width: min-content;
      display: flex;
      height: 61px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px 8px 0px 8px;
      overflow: hidden;
      /* flex: 1 0 0px; */
      position: relative;
      align-content: center;
      flex-wrap: nowrap;
      gap: 10px;
      border-color: $secondary;
      border-style: solid;
      border-top-width: 0px;
      border-bottom-width: 0px;
      border-left-width: 0px;
      border-right-width: 2px;
      cursor: pointer;
    }

    .controls {
      width: min-content;
      height: min-content;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: relative;
      align-content: center;
      flex-wrap: nowrap;

      .inner-left {
        box-sizing: border-box;
        width: min-content;
        height: 61px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 24px 0px 0px;
        overflow: hidden;
        position: relative;
        align-content: center;
        flex-wrap: nowrap;
        gap: 48px;
        border-color: $third;
        border-style: solid;
        border-top-width: 0px;
        border-bottom-width: 0px;
        border-left-width: 0px;
        border-right-width: 2px;

        .range-container {
          overflow: hidden;
        }
        .track-and-volume-container{
          display: flex;
          flex-direction: row;
          width: min-content;
          gap:16px;
          align-items: center;
          
          .track-name{
            width: max-content;
            color: $third;
            font-weight: 600;
          }
        }


        .volume {
          width: min-content;
          height: min-content;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          flex-wrap: nowrap;
          gap: 8px;

          .volume-icon{
            cursor: pointer;
          }
        }

        input[type="range"]{
          -webkit-appearance: none;
          appearance: none;
          cursor: pointer;
          width: 15rem;
          height: 8px;
          width: 80px;
          display: block;
          color: $white;
          background: $third;
          border-radius: 5px;
          background-image: linear-gradient($white, $white);
          background-repeat: no-repeat;

          /* Input Thumb */
          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            height: 0px;
            width: 0px;
          }

          /***** Chrome, Safari, Opera, and Edge Chromium *****/
          &::-webkit-slider-runnable-track {
            -webkit-appearance: none;
            box-shadow: none;
            border: none;
            background: transparent;
          }
          
          /******** Firefox ********/
          &::-moz-range-track {
            box-shadow: none;
            border: none;
            background: transparent;
          }
          /******** Edge ********/
          &::-ms-track {
            box-shadow: none;
            border: none;
            background: transparent;
          }
        }

      }

      .inner-right {
        box-sizing: border-box;
        width: min-content;
        height: 61px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 0px 0px 24px;
        overflow: hidden;
        position: relative;
        align-content: center;
        flex-wrap: nowrap;
        gap: 24px;

        .preview {
          box-sizing: border-box;
          width: min-content;
          height: min-content;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 4px 16px 4px 16px;
          background-color: $secondary;
          overflow: hidden;
          position: relative;
          align-content: center;
          flex-wrap: nowrap;
          gap: 8px;
          border-radius: 7px;
          cursor: pointer;
        }

        .share {
          box-sizing: border-box;
          width: min-content;
          height: min-content;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 8px 16px 8px 16px;
          background-color: $primary;
          overflow: hidden;
          position: relative;
          align-content: center;
          flex-wrap: nowrap;
          gap: 8px;
          border-radius: 8px;
          cursor: pointer;
        }

        span {
          width: auto;
          height: auto;
          white-space: pre;
          position: relative;
          font-weight: 600;
          font-style: normal;
          font-family: "Inter-SemiBold", "Inter", Verdana, sans-serif;
          color: $white;
          font-size: 16px;
          letter-spacing: 0px;
          line-height: 1.2;
        }
      }
    }
  }
}