@import "./custom.scss";

/* First Form  */
.video-form-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  align-self: center;
  font-size: 36px;
  max-width: 900px;
  margin: 56px 24px 56px 24px;
}

.video-form {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 32px;

  h1 {
    font-weight: 800;
  }

  .form-line{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;

    input {
      background-color: $third;
      border: 4px solid $white;
      height: min-content;
      padding: 8px 16px;
      color: $white;
      font-weight: 800;
      font-size: 0.8em;
      max-width: 800px;
      border-radius: 4px;

      &.error
      {
        animation: shake 0.2s ease-in-out 0s 2;
        box-shadow: 0 0 0.5em white;
        text-align: left;
      }
    }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $white;
      opacity: 0.5; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $white;
      opacity: 0.5;
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: $white;
      opacity: 0.5;
    }
  }

  .submit{
    padding: 16px 24px;
    background-color: $primary;
    border: none;
    color: $white;
    font-size: 24px;
    font-weight: 600;
    border-radius: 4px;
  }
}

.video-form #url,
.video-form input[type="submit"] {
  width: 100%;
}

@keyframes shake {
  0% { margin-left: 0rem; }
  25% { margin-left: 0.5rem; }
  75% { margin-left: -0.5rem; }
  100% { margin-left: 0rem; }
}