@import "./custom.scss";

.loading-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  align-content: center;
  flex-wrap: nowrap;

  .loading-inner {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    flex: 1 0 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 80px 0px 64px 0px;
    overflow: visible;
    position: relative;
    align-content: center;
    flex-wrap: nowrap;
    gap: 48px;

    .title {
      flex-shrink: 0;
      height: auto;
      white-space: pre-wrap;
      word-wrap: break-word;
      word-break: break-word;
      position: relative;
      font-weight: 800;
      font-style: normal;
      font-family: "Inter-ExtraBold", "Inter", sans-serif;
      color: #e8ecf1;
      font-size: 48px;
      letter-spacing: -1.5px;
      line-height: 1.2;

      &.small{
        font-size: 2em;
      }
    }

    .lottie-container {
      flex-shrink: 0;
      width: 100%;
      height: 25vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      //flex: 1 0 0px;
      position: relative;
      align-content: center;
      flex-wrap: nowrap;
      gap: 10px;
    }

    .quotes-container {
      box-sizing: border-box;
      flex-shrink: 0;
      width: 80%;
      height: min-content;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 56px 24px 24px 24px;
      flex: 1 0 0px;
      position: relative;
      align-content: center;
      flex-wrap: nowrap;
      gap: 24px;

      .quote-title {
        flex-shrink: 0;
        width: 100%;
        height: auto;
        white-space: pre-wrap;
        word-wrap: break-word;
        word-break: break-word;
        flex: 1 0 0px;
        position: relative;
        font-weight: 600;
        font-style: normal;
        font-family: "Inter-SemiBold", "Inter", sans-serif;
        color: #e8ecf1;
        font-size: 24px;
        letter-spacing: -1.5px;
        line-height: 1.2;
        text-align: center;
      }

      .quote {
        flex-shrink: 0;
        width: 100%;
        height: auto;
        white-space: pre-wrap;
        word-wrap: break-word;
        word-break: break-word;
        flex: 1 0 0px;
        position: relative;
        font-weight: 600;
        font-style: normal;
        font-family: "Inter-ExtraBold", "Inter", sans-serif;
        color: #e8ecf1;
        font-size: 36px;
        letter-spacing: -1.5px;
        line-height: 1.2;
        text-align: center;
      }

      .author {
        width: 100%;
        height: auto;
        white-space: pre-wrap;
        word-wrap: break-word;
        word-break: break-word;
        flex: 1 0 0px;
        position: relative;
        font-weight: 600;
        font-style: normal;
        font-family: "Inter-SemiBold", "Inter", sans-serif;
        color: #e8ecf1;
        font-size: 28px;
        letter-spacing: -1.5px;
        line-height: 1.2;
        text-align: right;
      }
    }
  }

  div {
    justify-content: center;
  }
  .warning{
    font-size: 1.1em;
    text-align: center;
  }
}

.video-container {
  .loading-container {
    color: #E8ECF1;
  }
}