@import "./custom.scss";

.video-container {
    box-sizing: border-box;
    width: min-content;
    height: min-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 0px 40px 0px;
    overflow: hidden;
    align-content: flex-start;
    flex-wrap: nowrap;
    gap: 16px;

    .top {
        flex-shrink: 0;
        width: min-content;
        height: min-content;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        align-content: center;
        flex-wrap: nowrap;
        gap: 8px;

        .video {
            flex-shrink: 0;
            width: 222px;
            height: 395px;
            display: block;
            overflow: hidden;
            position: relative;
            aspect-ratio: 0.5620253164556962 / 1;
            border-radius: 0px 0px 0px 0px;
            &:hover {
                .PauseIcon {        
                    display: inline;
                }
            }
            .brigthness {
                -webkit-filter: brightness(0.5);
                filter: brightness(0.5);
            }
            .frame1 {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;                
                overflow: hidden;
                align-content: center;
                flex-wrap: nowrap;
                gap: 10px;
                //background-image: url("https://via.placeholder.com/800x900");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
            }
        }

        .feedback {
            flex-shrink: 0;
            width: min-content;
            height: min-content;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            position: relative;
            align-content: center;
            flex-wrap: nowrap;
            gap: 24px;

            cursor: pointer;
        }
    }

    .bottom {
            width: 222px;
        height: min-content;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        flex: 1 0 0px;
        position: relative;
        align-content: center;
        flex-wrap: nowrap;
        gap: 16px;

        .edit {
            box-sizing: border-box;
            flex-shrink: 0;
            width: min-content;
            height: min-content;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 4px 12px 4px 8px;
            background-color: var(--Third, #47596c);
            min-height: 30px;
            overflow: hidden;
            position: relative;
            align-content: center;
            flex-wrap: nowrap;
            gap: 8px;
            border-radius: 8px;
            cursor: pointer;
        }

        .share-video {
            box-sizing: border-box;
            width: min-content;
            height: min-content;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 4px 12px 4px 8px;
            background-color: var(--Primary, #0071eb);
            min-height: 30px;
            overflow: hidden;
            position: relative;
            align-content: center;
            flex-wrap: nowrap;
            gap: 8px;
            border-radius: 8px;
            cursor: pointer;
        }
    }
}