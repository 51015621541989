@import "./custom.scss";

.editor {
  width: 100%;
  flex: 1 0 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  position: relative;
  align-content: center;
  flex-wrap: nowrap;

  .sides {
    flex: 1 0 0px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    position: relative;
    align-content: flex-start;
    flex-wrap: nowrap;

    .left {
      // height: calc(100vh - 290px);
      height: 100%;
      flex: 1 0 0px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      background-color: $third;
      overflow: hidden;
      position: relative;
      align-content: center;
      flex-wrap: nowrap;
    }

    .right {
      // height: calc(100vh - 290px);
      height: 100%;
      flex: 1.3 0 0px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      background-color: $secondary;
      overflow: auto;
      position: relative;
      align-content: center;
      flex-wrap: nowrap;
      gap: 10px;
    }
  }
  .timeline {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100vw;
    overflow: scroll;
    height: min-content;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 0px 16px 24px;
    background-color: $white;
    overflow: auto;
    position: relative;
    align-content: center;
    flex-wrap: nowrap;
    gap: 8px;
    border-radius: 0px 0px 0px 0px;
  }
}
