@import "./custom.scss";

.form-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  .modal-title{
    font-size: 1.5em;
    padding-bottom: 16px;
    font-weight: 600;
  }

  input {
    background-color: $third;
    border: 4px solid $white;
    height: min-content;
    padding: 8px 16px;
    color: $white;
    font-weight: 800;
    font-size: 1em;
    max-width: 800px;
    border-radius: 4px;

    &.error
    {
      animation: shake 0.2s ease-in-out 0s 2;
      box-shadow: 0 0 0.5em white;
      text-align: left;
    }

    &.checkbox{
      margin-top: 6px;
      // height: 16px;
      // width: 16px;
    }
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $white;
    opacity: 0.5; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $white;
    opacity: 0.5;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $white;
    opacity: 0.5;
  }

  .gdpr-checkbox{
    display: flex;
    gap: 16px;
    align-items: flex-start;
    padding: 0;
  }

  .gdpr{
    flex-shrink: 1;
    font-size: 1em;
  }

  .errorContainer{
    height: 24px;
    
    .error{
      font-weight: 700;
      color: #eb3700;
      background-color: inherit;
      text-align: left;
      font-size: 0.8em;
      line-height: 1.1em;
      padding: 0;
    }
  }
  .submit{
    text-align: center;
    background-color: $primary;
    border: none;
  }
  .secondary{
    text-align: center;
    font-weight: 600;
    font-size: 1.1em;
    cursor: pointer;
    color: $third;

    &:hover{
      text-decoration: underline;
    }
  }
}