@import "./custom.scss";

.title {
  flex-shrink: 0;
  width: 100%;
  margin: 8px 0;
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  overflow: hidden;
  flex: 1 0 0px;
  position: relative;
  padding: 0px 0px 0px 0px;
  align-content: flex-end;
  flex-wrap: nowrap;
  border-radius: 0px 0px 0px 0px;

  .categorie {
    flex-shrink: 0;
    width: auto;
    height: auto;
    white-space: pre;
    position: relative;
    line-height: 1.2;
    font-weight: 700;
    font-style: normal;
    font-family: "Inter-Bold", "Inter", Verdana, sans-serif;
    color: $white;
    font-size: 20px;
    letter-spacing: 0px;
  }

  .seeall {
    flex-shrink: 0;
    width: auto;
    height: auto;
    white-space: pre;
    position: relative;
    font-weight: 700;
    font-style: normal;
    font-family: "Inter-Bold", "Inter", Verdana, sans-serif;
    color: $white;
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 1.4;
    cursor: pointer;
  }
}

.frame {
  flex-shrink: 0;
  width: 1px;
  height: 120px;
  display: block;
  overflow: hidden;
  flex: 1 0 0px;
  position: relative;
  border-radius: 0px 0px 0px 0px;

  .arrow-block {
    width: 32px;
    height: 120px;
    display: block;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--Background, rgb(23, 33, 44)) 100%);
    overflow: hidden;
    position: absolute;
    border-radius: 0px 0px 0px 0px;
  }
}

.see-all a {
  flex-shrink: 0;
  width: auto;
  height: auto;
  white-space: pre;
  position: relative;
  font-weight: 700;
  font-style: normal;
  font-family: "Inter-Bold", "Inter", Verdana, sans-serif;
  color: $white;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 1.4;
  text-decoration: none;
}

.container-horizontal {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: $secondary;
}

.item {
  height: 100%;
  max-width: 168px;
  margin-right: 10px;
  display: flex;

  &.music{
    display: flex;
    flex-direction: column;
    cursor: pointer;
    max-width: 100%;
    
    .music-top{
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 4px 8px;
      align-items: center;
      .music-title{
        font-weight: 600;
      }
      .select{
        font-size: 0.8em;
      }
    }
    audio{
      max-width: 250px;
    }
  }
}

.item > img {
  object-fit: cover;
  max-width: inherit;
}

.arrow {
  //width: 32px;
  cursor: pointer;
  display: flex;
  position: absolute;
  z-index: 1;
}

.arrow-right {
  right: 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--Background, rgb(23, 33, 44)) 100%);
}

.arrow-left {
  left: 0;
  background: linear-gradient(90deg, var(--Background, rgb(23, 33, 44)) 0%, rgba(255, 255, 255, 0) 100%);
}

.arrow-right > span,
.arrow-left > span {
  align-items: center;
  display: flex;
}

.textContainer {
  height: fit-content;
  color: #000000;
  text-align: center;
  background-color: #fff;
  border-radius: 9px;
  align-items: center;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-wrap: wrap;
  align-self: center !important;
  cursor: pointer;
}

.box {
  width: 168px;
  padding: 4px 8px;
  line-height: 1.5;
  align-self: center;
  height: auto;
  align-content: center;
  display: flex;
  justify-content: center;
}

.font-lg {
  font-size: 0.95em;
}

.font-md {
  font-size: 0.85em;
}

.font-sm {
  font-size: 0.7em;
}
