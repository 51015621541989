@import "./custom.scss";

.select-container{
  overflow: visible;
  height: 56px;
  cursor: pointer;
  .select {
    background-color: $third;
    border-radius: 4px;
    padding: 8px 4px 8px 16px;
    color: $white;
    font-size: 36px;
    font-weight: 800;
    display: flex;
    flex-direction: row;
    width: max-content;
    height: 56px;
    border: none;
    align-items: center;
    gap: 8px;
    // word-break: keep-all;
  }
  .drop-down{
    display: none;
    border-radius: 8px;
    background: $white;
    overflow: hidden;
  
    &.open {
      position: absolute;
      top: 16px;
      z-index: 10;
      display:flex;
      flex-direction: column;
    }
    .option {
      display: flex;
      padding: 4px 16px;
      align-items: center;
      color: $secondary;
      border-bottom: 2px solid rgb(216, 221, 228);
      word-break: keep-all;

      &:hover{
        background-color: #C0CBD8;
      }
  
      &.selected {
        background-color: #C0CBD8;
      }
  
      &:last-child {
        border-bottom: none;
      }

      &.disabled {
        background: repeating-linear-gradient(
          45deg,
          #C0CBD8,
          #C0CBD8 10px,
          #a8b1bd 10px,
          #a8b1bd 20px
        );
      }
    }
  }
}
