@import "./custom.scss";

.modal-body{
  display: flex;
  justify-content: center;
}

.video-container{
  display: flex;
  justify-content: center;
  min-width: 270px;
  min-height: 480px;
  align-items: center;
}

.btn.btn-primary{
  color: $white;
  display: flex;
  gap: 8px;
  padding: 8px 16px;
  font-weight: bold;

  &:disabled{
    color: $white;
  }
}