$primary: #0071EB;
$secondary: #313F4D;
$third: #5b728b;
$background: #17212C;
$white: #E8ECF1;

$body-bg: $background;
$body-color: $white;
$modal-content-bg: $background;
$border-color: rgba(0, 0, 0, 0);
$btn-close-color: $white;
$modal-footer-margin-between: 0.5rem;
$modal-inner-padding: 1.5rem;
$link-color: $third;
$btn-hover-color: $white;

@import "../../node_modules/bootstrap/scss/bootstrap";

html {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

body {
  flex-grow: 1;
  display: flex;
  background-image: linear-gradient(to right top, #17212c, #102339, #072545, #022652, #06265d);
}

#root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.App{
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}